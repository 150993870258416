import { SenecaResponse } from "atfcore-commonclasses";
import { User } from "atfcore-commonclasses";
import { NotificationQueue } from "atfcore-commonclasses";
import { IApplicationScope } from "../../shared/global/applicationScope";
import { UserAcknowledges } from "atfcore-commonclasses";

module CommonServices {
    var app = angular.module("app");

    export interface IRootScope extends angular.IRootScopeService {
        adminMode: boolean;
        teacherMode: boolean;
    }

    export interface ICommonServicesControllerScope extends IApplicationScope {
        logout: Function;
        notificationCount: number;
        goToNetwork: Function;
        switchTeacherProfile: Function;
        goToHome: Function;
        goToAdmin: Function;
        goToSettings: Function;
        goToLogin: Function;
        isExpanced: boolean;
        expandMainHeader: Function;
        hamburgerMenu: string;
        isFabOpen: Boolean;
        openLeftMenu: Function;
        openDiaryMenu: Function;
        globalApplicationData: any;
        topDirections: any;
        bottomDirections: any;
        availableModes: any;
        selectedMode: string;
        availableDirections: any;
        selectedDirection: string;
        canAccessToApplication: boolean;
        checkIfHasAccount: Function;
        hasNotApplicationEnabled: Function;
        getDownloadTempFileUrl: Function;
        getExternalResourceName: Function;
        getExternalResourceUrl: Function;
        teacherMode: boolean;
    }

    export interface ICommonServicesStateParams extends angular.ui.IStateParamsService {
        userId: string;
    }

    export class CommonServicesController {
        static $inject = ["$scope", "$state", "$stateParams", "LibraryApplicationData", "$rootScope", "GlobalApplicationData", "UserService", "NotificationService", "$timeout", "toaster", "$mdSidenav", "$translate", "AnagService", "$localStorage", "$sessionStorage", "$window", "SessionStorageService"];

        public notificationTimeout: any;

        constructor(protected $scope: CommonServices.ICommonServicesControllerScope,
            protected $state: angular.ui.IStateService,
            protected $stateParams: ICommonServicesStateParams,
            protected LibraryApplicationData: any,
            protected $rootScope: any,
            protected GlobalApplicationData: any,
            protected UserService: any,
            protected NotificationService: any,
            protected $timeout: any,
            protected toaster: any,
            protected $mdSidenav: any,
            protected $translate: angular.translate.ITranslateService,
            protected AnagService: any,
            protected $localStorage: any,
            protected $sessionStorage: any,
            protected $window: any,
            protected SessionStorageService: any,
            protected $on: any,
            protected cleanJwt: any
        ) {
            let _self = this;

            $scope.notificationCount = 0;

            // Controllo se l'utente ha un account nell'applicazione
            $scope.canAccessToApplication = false;
            $scope.checkIfHasAccount = this.checkIfHasAccount;
            $scope.checkIfHasAccount();

            // Icona Hamburger Menu
            $scope.hamburgerMenu = 'menu';

            $scope.teacherMode = $sessionStorage.teacherMode;

            // Metodo per download file temporanei dal server
            $scope.getDownloadTempFileUrl = this.getDownloadTempFileUrl;
            $scope.getExternalResourceName = this.getExternalResourceName;
            $scope.getExternalResourceUrl = this.getExternalResourceUrl;

            // Esegue il logout e porta nella pagina di login
            $scope.logout = this.logout;

            // Controllo se ho il token (mi serve per switchare l'header)
            $scope.globalApplicationData = GlobalApplicationData;

            // Disabilita i pulsanti nell'header (link alla Library, CM..) in base alle abilitazioni dell'utente
            $scope.hasNotApplicationEnabled = this.hasNotApplicationEnabled;

            $scope.isFabOpen = false;

            // Gestisce l'espansione dell'header
            $scope.isExpanced = false;
            $scope.expandMainHeader = this.expandMainHeader;

            //Tool fab
            $scope.topDirections = ['left', 'up'];
            $scope.bottomDirections = ['down', 'right'];


            $scope.availableModes = ['md-fling', 'md-scale'];
            $scope.selectedMode = 'md-fling';

            $scope.availableDirections = ['up', 'down', 'left', 'right'];
            $scope.selectedDirection = 'down';

            // Porta nel Network
            $scope.goToNetwork = this.goToNetwork;

            // Porta nel Login
            $scope.goToLogin = this.goToLogin;

            // Porta alla home
            $scope.goToHome = this.goToHome;

            // Switch profilo docente
            $scope.switchTeacherProfile = this.switchTeacherProfile;

            // Porta alla parte di amministrazione
            $scope.goToAdmin = this.goToAdmin;

            // Porta alle Impostazioni
            $scope.goToSettings = this.goToSettings;

            // Apertura Sidenav di sinistra
            $scope.openLeftMenu = this.openLeftMenu;

            // Apertura Sidenav del Diario
            $scope.openDiaryMenu = this.openDiaryMenu;

            let imagePath = 'assets/img/placeholder.png';

            //Tool fab
            $scope.topDirections = ['left', 'up'];
            $scope.bottomDirections = ['down', 'right'];

            $scope.availableModes = ['md-fling', 'md-scale'];
            $scope.selectedMode = 'md-fling';

            $scope.availableDirections = ['up', 'down', 'left', 'right'];
            $scope.selectedDirection = 'down';

            // Mi metto in ascolto dell'evento che forza un refresh delle notifiche
            let cleanUpNotificationRefreshEvent = $scope.$on('notificationsRefresh', (event: any, args: any) => {
                this.getNotificationCount(this);
            });

            this.$scope.$on('$destroy', () => {
                //cleanUpNotificationRefreshEvent();
                // in questo punto mi è sufficiente cancellare il timeout
                if (this.$rootScope.notificationTimeout) {
                    this.$timeout.cancel(this.$rootScope.notificationTimeout);
                }
            });

            this.getNotificationCount(this);

            this.$rootScope.$on('notificationClick', () => {
                if (this.$scope.isExpanced == true)
                    this.$scope.expandMainHeader();
            });
        }

        // Controllo se l'utente ha un account nell'applicazione
        public checkIfHasAccount = () => {
            if (this.$stateParams.userId) {
                // Chiamo il Server per verificare se ho già aperto e concluso il Tutorial   
                this.AnagService.getUserById.query({
                    userId: this.$stateParams.userId
                }).$promise
                    .then((data: SenecaResponse<User>) => {
                        // Se c'è segnalo l'errore
                        if (data.error) {
                            // Dati non validi, quindi alzo l'errore
                            this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                        } else {
                            // Mi è tornato un account, quindi verifico che sia abilitato ad accedere al dp
                            this.AnagService.getApplicationsEnabled.query({
                                userId: this.$stateParams.userId
                            }).$promise
                                .then((applications: SenecaResponse<Array<String>>) => {
                                    // Se c'è segnalo l'errore
                                    if (applications.error) {
                                        // Dati non validi, quindi alzo l'errore
                                        this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                                    } else {
                                        for (let i = 0; i < applications.response.length; i++) {
                                            if (applications.response[i] == this.LibraryApplicationData.constants.LIBRARY) {
                                                this.$scope.canAccessToApplication = true;
                                            }
                                        }
                                    }
                                })
                                .catch((error: any) => {
                                    // Non mostro la modale di errore se ho cancellato volutamente la richiesta
                                    if (!error || error.config.timeout.$$state.status !== 1) {
                                        // Verifico se è un problema di connettività
                                        let errorMessage: string = null;

                                        // Nuovo oggetto d'errore
                                        let newError: any = {
                                            severity: "danger"
                                        }

                                        if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
                                            // Problema di connettività
                                            errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
                                            newError.hideUnknown = true;
                                        } else {
                                            // Messaggio di errore generico
                                            errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
                                        }

                                        // Imposto il messaggio
                                        newError.message = errorMessage;

                                        // Lo aggiungo alla lista
                                        let errors: Array<any> = [];
                                        errors.push(newError);

                                        // E apro la modale
                                        this.$rootScope.$emit("showApplicationModalErrors", errors);
                                    }
                                });
                        }
                    })
                    .catch((error: any) => {
                        // Non mostro la modale di errore se ho cancellato volutamente la richiesta
                        if (!error || error.config.timeout.$$state.status !== 1) {
                            // Verifico se è un problema di connettività
                            let errorMessage: string = null;

                            // Nuovo oggetto d'errore
                            let newError: any = {
                                severity: "danger"
                            }

                            if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
                                // Problema di connettività
                                errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
                                newError.hideUnknown = true;
                            } else {
                                // Messaggio di errore generico
                                errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
                            }

                            // Imposto il messaggio
                            newError.message = errorMessage;

                            // Lo aggiungo alla lista
                            let errors: Array<any> = [];
                            errors.push(newError);

                            // E apro la modale
                            this.$rootScope.$emit("showApplicationModalErrors", errors);
                        }
                    });
            }
        }

        // Disabilita i pulsanti nell'header (link alla Library, CM..) in base alle abilitazioni dell'utente
        public hasNotApplicationEnabled = (application: string) => {
            // Se nell'elenco di permessi dell'utente non c'è l'abilitazione da me richiesta, disabilito il pulsante nell'header
            if (this.$scope.globalApplicationData.applicationsEnabled.indexOf(application) != -1) {
                return false;
            } else {
                return true;
            }
        }

        // Esegue il logout e porta nella pagina di login
        public logout = () => {
            // Recupero i dati di SSO prima di eliminare il token
            let nameId = this.$scope.globalApplicationData.jwtPayload.params && this.$scope.globalApplicationData.jwtPayload.params.SAML_NAME_ID;
            let sessionIndex = this.$scope.globalApplicationData.jwtPayload.params && this.$scope.globalApplicationData.jwtPayload.params.SAML_SESSION_INDEX;
            // Se sono in SSO vado al logout specifico, altrimenti chiamo quello locale
            if (nameId && sessionIndex) {
                this.$window.location.href = this.$scope.globalApplicationData.ssoRedirectLogoutPage + "?SAML_NAME_ID=" + encodeURIComponent(nameId) + "&SAML_SESSION_INDEX=" + encodeURIComponent(sessionIndex);
            }
            else {
                // Chiamo il Server per fare il logout locale
                this.UserService.logout.get().$promise
                    .then((data: SenecaResponse<any>) => {
                        // Se c'è segnalo l'errore
                        if (data.error) {
                            // Dati non validi, quindi alzo l'errore
                            this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                        } else {
                            // Rimuovo il token dalla sessione locale del browser
                            this.$scope.cleanJWT();
                            // Azzero contatore notifiche
                            this.$scope.notificationCount = 0;
                            // Rimando al login
                            this.$state.go('app.localLogin');
                            // Chiudo la barra
                            this.$scope.isExpanced = false;
                        }
                    })
                    .catch((error: any) => {
                        // Non mostro la modale di errore se ho cancellato volutamente la richiesta
                        if (!error || error.config.timeout.$$state.status !== 1) {
                            // Verifico se è un problema di connettività
                            let errorMessage: string = null;

                            // Nuovo oggetto d'errore
                            let newError: any = {
                                severity: "danger"
                            }

                            if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
                                // Problema di connettività
                                errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
                                newError.hideUnknown = true;
                            } else {
                                // Messaggio di errore generico
                                errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
                            }

                            // Imposto il messaggio
                            newError.message = errorMessage;

                            // Lo aggiungo alla lista
                            let errors: Array<any> = [];
                            errors.push(newError);

                            // E apro la modale
                            this.$rootScope.$emit("showApplicationModalErrors", errors);
                        }
                    });
            }
        }

        // Apertura Sidenav di sinistra
        public openLeftMenu = () => {
            this.$mdSidenav('left').toggle();
        }

        // Gestisce l'espansione dell'header
        public expandMainHeader = () => {
            if (this.$scope.isExpanced == true) {
                this.$scope.isExpanced = false;
            } else {
                this.$scope.isExpanced = true;
            }
        }

        // Apertura Sidenav del Diario
        public openDiaryMenu = () => {
            this.$mdSidenav('right').toggle();
        }

        // Porta nel Network
        public goToNetwork = () => {
            this.$state.go('app.libraryApp.network');
        }

        // Porta nel Login
        public goToLogin = () => {
            this.$state.go('app.login');
        }

        public getExternalResourceUrl = (requestUrl: string) => {
            return this.GlobalApplicationData.applicationContext + 'rest-api/library-mediator/proxy-url?requestUrl=' + encodeURIComponent(requestUrl);
        }

        public getExternalResourceName = (requestUrl: string, requestName: string) => {
            return this.GlobalApplicationData.applicationContext + 'rest-api/library-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
        }

        public getDownloadTempFileUrl = (filename: string) => {
            return this.GlobalApplicationData.applicationContext + 'rest-api/library-mediator/download-temp-file?filename=' + filename;
        }

        // Porta nella home page del lato utente. Se sono già lato utente, torno alla pagina di ricerca; altrimenti, se sto switchando da amministratore ad utente, devo restare nella stessa pagina
        public goToHome = (stayInCurrentPage: boolean, cleanSessionStorage: boolean, closeTeacherMode?: boolean) => {
            // Salvo la pagina attuale
            let currentState = this.$state.current.name;
            // e gli eventuali parametri
            let currentParams: any = this.$stateParams;

            // Sto eseguendo uno switch, quindi pulisco tutti i filtri (per evitare ad esempio questo caso: sono in home admin, eseguo una ricerca con un filtro che c'è solo in admin come i tag liberi, entro
            // nel dettaglio di un oggetto, faccio lo switch a lato utente e torno in home.. avrò un errore perché la tab dei tag liberi non esiste più)
            this.SessionStorageService.empty("SEARCHES");
            if (closeTeacherMode) {
                this.$sessionStorage.teacherMode = false;
            }

            // se richiesto, pulisco il session storage contenente le ricerche precedenti
            if (cleanSessionStorage) {
                let currentAdminMode = this.$sessionStorage.adminMode;
                // Pulisco tutto
                sessionStorage.clear();
                // E setto l'attuale modalità utente
                sessionStorage.setItem("ngStorage-adminMode", currentAdminMode);
                if (closeTeacherMode) {
                    sessionStorage.setItem("ngStorage-teacherMode", "false");
                }
            }

            if (stayInCurrentPage) {
                if (currentState == "app.libraryApp.itemDetail") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.itemDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.projectDetail") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.projectDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.sectionDetail") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.sectionDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.editLearningPlanAdmin") {
                    // L'ui-routing si occuperà del forzare il sessionStorage
                    this.$state.go("app.libraryApp.editLearningPlan", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.addItemToCurrentLearningPlan") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.addItemToCurrentLearningPlan", { itemId: currentParams.itemId, isDraft: currentParams.isDraft }, { reload: true });
                } else if (currentState == "app.libraryApp.survey") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.survey", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.surveyResult") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.surveyResult", { surveyId: currentParams.surveyId }, { reload: true });
                } else if (currentState == "app.libraryApp.newLearningPlan") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.newLearningPlan", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailSec") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.itemDetailSec", { projectId: currentParams.projectId, sectionId: currentParams.sectionId, itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailLp") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.itemDetailLp", { lpId: currentParams.lpId, itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailToAddToLearningPlan") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.itemDetailToAddToLearningPlan", { editingLearningPlanId: currentParams.editingLearningPlanId, itemToAddId: currentParams.itemToAddId, isDraft: currentParams.isDraft }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailEditLp") {
                    this.$sessionStorage.adminMode = false;
                    this.$state.go("app.libraryApp.itemDetailEditLp", { editingLpId: currentParams.editingLpId, itemId: currentParams.itemId }, { reload: true });
                } else {
                    // In tutti gli altri casi vado nella Home
                    this.$state.go('app.libraryApp.home', {}, { reload: true });
                }
            } else {
                // Se sono già nell'home, svuoto la ricerca
                if (currentState == "app.libraryApp.home") {
                    this.$state.go("app.libraryApp.home", { searchId: null }, { reload: true });
                } else {
                    this.$state.go("app.libraryApp.home", {}, { reload: true });
                }
            }
        }

        // Switch profilo docente (uguale all'utente normale, ma non crea engagement)
        public switchTeacherProfile = (stayInCurrentPage: boolean, cleanSessionStorage: boolean) => {
            // Salvo la pagina attuale
            let currentState = this.$state.current.name;
            // e gli eventuali parametri
            let currentParams: any = this.$stateParams;

            // Sto eseguendo uno switch, quindi pulisco tutti i filtri (per evitare ad esempio questo caso: sono in home admin, eseguo una ricerca con un filtro che c'è solo in admin come i tag liberi, entro
            // nel dettaglio di un oggetto, faccio lo switch a lato utente e torno in home.. avrò un errore perché la tab dei tag liberi non esiste più)
            this.SessionStorageService.empty("SEARCHES");

            // se richiesto, pulisco il session storage contenente le ricerche precedenti
            if (cleanSessionStorage) {
                // Pulisco tutto
                sessionStorage.clear();
                // E setto l'attuale modalità utente
                sessionStorage.setItem("ngStorage-adminMode", "false");
                sessionStorage.setItem("ngStorage-teacherMode", "true");
            }

            this.$sessionStorage.teacherMode = true;
            this.$sessionStorage.adminMode = false;

            if (stayInCurrentPage) {
                if (currentState == "app.libraryApp.itemDetail") {
                    this.$state.go("app.libraryApp.itemDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.projectDetail") {
                    this.$state.go("app.libraryApp.projectDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.sectionDetail") {
                    this.$state.go("app.libraryApp.sectionDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.editLearningPlanAdmin") {
                    // L'ui-routing si occuperà del forzare il sessionStorage
                    this.$state.go("app.libraryApp.editLearningPlan", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.addItemToCurrentLearningPlan") {
                    this.$state.go("app.libraryApp.addItemToCurrentLearningPlan", { itemId: currentParams.itemId, isDraft: currentParams.isDraft }, { reload: true });
                } else if (currentState == "app.libraryApp.survey") {
                    this.$state.go("app.libraryApp.survey", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.surveyResult") {
                    this.$state.go("app.libraryApp.surveyResult", { surveyId: currentParams.surveyId }, { reload: true });
                } else if (currentState == "app.libraryApp.newLearningPlan") {
                    this.$state.go("app.libraryApp.newLearningPlan", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailSec") {
                    this.$state.go("app.libraryApp.itemDetailSec", { projectId: currentParams.projectId, sectionId: currentParams.sectionId, itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailLp") {
                    this.$state.go("app.libraryApp.itemDetailLp", { lpId: currentParams.lpId, itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailToAddToLearningPlan") {
                    this.$state.go("app.libraryApp.itemDetailToAddToLearningPlan", { editingLearningPlanId: currentParams.editingLearningPlanId, itemToAddId: currentParams.itemToAddId, isDraft: currentParams.isDraft }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailEditLp") {
                    this.$state.go("app.libraryApp.itemDetailEditLp", { editingLpId: currentParams.editingLpId, itemId: currentParams.itemId }, { reload: true });
                } else {
                    // In tutti gli altri casi vado nella Home
                    this.$state.go('app.libraryApp.home', {}, { reload: true });
                }
            } else {
                // Se sono già nell'home, svuoto la ricerca
                if (currentState == "app.libraryApp.home") {
                    this.$state.go("app.libraryApp.home", { searchId: null }, { reload: true });
                } else {
                    this.$state.go("app.libraryApp.home", {}, { reload: true });
                }
            }
        }

        // Porta alla parte di amministrazione. Se sono già in lato admin, torna alla pagina di ricerca; altrimenti, se sto switchando da utente ad amministratore, devo restare nella stessa pagina
        public goToAdmin = (stayInCurrentPage: boolean, cleanSessionStorage: boolean) => {
            // Salvo la pagina attuale
            let currentState = this.$state.current.name;
            // e gli eventuali parametri
            let currentParams: any = this.$stateParams;

            // Sto eseguendo uno switch, quindi pulisco tutti i filtri (per evitare ad esempio questo caso: sono in home admin, eseguo una ricerca con un filtro che c'è solo in admin come i tag liberi, entro
            // nel dettaglio di un oggetto, faccio lo switch a lato utente e torno in home.. avrò un errore perché la tab dei tag liberi non esiste più)
            this.SessionStorageService.empty("SEARCHES");

            this.$sessionStorage.teacherMode = false;

            // se richiesto, pulisco il session storage contenente le ricerche precedenti
            if (cleanSessionStorage) {
                let currentAdminMode = this.$sessionStorage.adminMode;
                // Pulisco tutto
                sessionStorage.clear();
                // E setto l'attuale modalità utente
                sessionStorage.setItem("ngStorage-adminMode", currentAdminMode);
                sessionStorage.setItem("ngStorage-teacherMode", "false");
            }

            if (stayInCurrentPage) {
                // Se sono nella pagina di ricerca, porto nella pagina di ricerca di amministrazione
                if (currentState == "app.libraryApp.home") {
                    this.$state.go("app.libraryApp.homeAdmin", { searchId: null }, { reload: true });
                }

                // In tutti gli altri casi il nome dello state sarà lo stesso (fatta eccezione per alcuni casi), ma bisogna forzare il sessionStorage affinché riconosca il passaggio da utente ad amministratore. E' necessario, inoltre, passare i parametri che la pagina richiede e forzare il reload della pagina per ricaricare i componenti grafici
                else if (currentState == "app.libraryApp.itemDetail") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.itemDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.projectDetail") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.projectDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.sectionDetail") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.sectionDetail", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.editLearningPlan") {
                    // L'ui-routing si occuperà del forzare il sessionStorage
                    this.$state.go("app.libraryApp.editLearningPlanAdmin", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.addItemToCurrentLearningPlan") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.addItemToCurrentLearningPlan", { itemId: currentParams.itemId, isDraft: currentParams.isDraft }, { reload: true });
                } else if (currentState == "app.libraryApp.survey") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.survey", { itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.surveyResult") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.surveyResult", { surveyId: currentParams.surveyId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailSec") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.itemDetailSec", { projectId: currentParams.projectId, sectionId: currentParams.sectionId, itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailLp") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.itemDetailLp", { lpId: currentParams.lpId, itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailEditLp") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.itemDetailEditLp", { editingLpId: currentParams.editingLpId, itemId: currentParams.itemId }, { reload: true });
                } else if (currentState == "app.libraryApp.itemDetailToAddToLearningPlan") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.itemDetailToAddToLearningPlan", { editingLearningPlanId: currentParams.editingLearningPlanId, itemToAddId: currentParams.itemToAddId, isDraft: currentParams.isDraft }, { reload: true });
                } else if (currentState == "app.libraryApp.newLearningPlan") {
                    this.$sessionStorage.adminMode = true;
                    this.$state.go("app.libraryApp.newLearningPlanAdmin", { itemId: currentParams.itemId }, { reload: true });
                }
            } else {
                // Se sono già nell'home, svuoto la ricerca
                if (currentState == "app.libraryApp.homeAdmin") {
                    this.$state.go("app.libraryApp.homeAdmin", { searchId: null }, { reload: true });
                } else {
                    this.$state.go("app.libraryApp.homeAdmin", {}, { reload: true });
                }
            }
        }

        // Porta alle Impostazioni
        public goToSettings = () => {
            this.$state.go('app.libraryApp.settings');
        }

        // Cerco le notifiche da mostrare nell'header, utilizzando un polling continuo
        public getNotificationCount(_self: any) {
            if (_self.$scope.globalApplicationData.jwtPayload) {
                if (_self.$rootScope.notificationTimeout) {
                    _self.$timeout.cancel(_self.$rootScope.notificationTimeout);
                }
                _self.notificationTimeout = null;
                _self.NotificationService.notificationCount.query({
                }).$promise
                    .then((res: SenecaResponse<any>) => {
                        if (res.response) {
                            _self.$scope.notificationCount = res.response;
                        }
                        // promise del timeout
                        _self.$rootScope.notificationTimeout = _self.$timeout(_self.getNotificationCount, 30000, true, _self);
                    })
                    .catch((error: Error) => {
                        // In caso di errore non mostro niente, ma continuo il polling
                        // promise del timeout
                        _self.$rootScope.notificationTimeout = _self.$timeout(_self.getNotificationCount, 30000, true, _self);
                    });
            }
        }


    }
    app.controller("CommonServicesController", CommonServices.CommonServicesController);
}